<template>
  <v-row>
    <v-col>
      <v-row class="text-h5"
        ><v-col>{{ symbol }}</v-col
        ><v-col class="text-h6 text-left" v-if="showType !== ''">({{ showType }})</v-col></v-row
      >
      <v-row class="text-h5"
        ></v-row
      >
      <v-row
        ><v-col>
          <orderbook-item
            :data="asks"
            :hasHeader="true"
            :color="upperColor"
            :width="widthInput"
          ></orderbook-item> </v-col
      ></v-row>

      <v-row
        ><v-col
          ><orderbook-item
            :data="bids"
            :hasHeader="false"
            :color="lowerColor"
            :width="widthInput"
          ></orderbook-item></v-col
      ></v-row>
    </v-col>
  </v-row>
</template>

<script>
import OrderbookItem from "./Orderbook.vue";

import { is_futures } from "@/utils";

export default {
  name: "OrderbookPanel",

  components: {
    OrderbookItem,
  },

  props: {
    symbol: String,
    type: String,
    asks: Array,
    bids: Array,
    upperColor: String,
    lowerColor: String,
    widthInput: Array,
  },

  watch: {
  },

  computed: {
    showType: function() {
      if (is_futures(this.type)) {
        return 'F'
      } else {
        return 'S'
      }
    }
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>
