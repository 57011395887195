<template>
  <v-breadcrumbs :items="breadcrumbs_items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        style="cursor: pointer"
        @click="jump(item.href)"
        :disabled="item.disabled === undefined ? true : item.disabled"
      >
        {{ item.text === undefined ? '' : item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>

export default {
  name: "BreadCrumbs",

  props: {
    breadcrumbs_items: {
      type: Array,
      default: () => [],
    },
  },

  mounted: function () {},
  watch: {},
  computed: {},
  data() {
    return {};
  },

  methods: {},
};
</script>
