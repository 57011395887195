<template>
  <div>
    <v-data-table sort-by="startTime" :sort-desc="true" dense width="100%" :headers="task_header" :items="tasks" class="elevation-1">

      <template v-if="isAlive" v-slot:top>
        <v-row justify="end">
          <v-col class="ma-5" cols="2">
            <v-btn :disabled="tasks.length === 0" @click="tryToCoverAll">Cancel All</v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.symbol="{ item }">
        <b>{{ item.symbol }}</b>
        <!-- <v-col :style="`color: ${item.positionSide === 'LONG' ? 'green': 'red'}`">{{ item.symbol.split('|')[1] }}</v-col>-->
      </template>

      <template v-slot:item.side="{ item }">
        <b :style="`color: ${item.side === 'BUY' ? 'green' : 'red'}`">{{ item.side }}</b>
      </template>

      <template v-slot:item.startTime="{ item }">
        <b>{{ new Date(item.startTime).toLocaleString() }}</b>
      </template>

      <template v-slot:item.deadline="{ item }">
        <b>{{ new Date(item.deadline).toLocaleString() }}</b>
      </template>

      <template v-slot:item.status="{ item }">
        <b>{{ item.status }}</b><b v-if="item.forceFinish == true" style="color: red;">(CANCELLED)</b>
      </template>

      <template v-if="isAlive" v-slot:item.actions="{ item }">
        <v-row>
          <v-col cols="1">
            <v-icon small @click="tryToCover(item)">mdi-delete-empty</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <confirm-dialog max_width="400px" text="do u wanna cancel all tasks?" :dialogFlag="dlgCoverAll"
      :cancelFunc="closeCoverAllDlg" :confirmFunc="coverAll"></confirm-dialog>

    <confirm-dialog max_width="400px" text="do u wanna cancel the task?" :dialogFlag="dlgCover"
      :cancelFunc="closeCoverDlg" :confirmFunc="cover"></confirm-dialog>

  </div>
</template>

<script>
import { tradeMixin } from "@/mixins";

import { cancel_strategy_task } from '@/api';

import ConfirmDialog from "@/components/base/ConfirmDialog";
import PositionEditDlg from "@/views/dashboard/component/PositionEditDlg";

export default {
  name: "TaskTable",

  components: {
    ConfirmDialog,
    PositionEditDlg,
  },

  mixins: [tradeMixin],

  props: {
    isAlive: {
      type: Boolean,
      default: false,
    },
    strategyName: {
      type: String,
    },
    instanceId: {
      type: String,
    },
    gatewayName: {
      type: String,
    },
    tasks: Array,
  },

  methods: {

    tryToAdd() {
      this.isNew = true;
      this.dlgEdit = true;
    },

    tryToEdit(item) {
      this.isNew = false;
      this.item = item;
      console.log(`tryToEdit: ${this.item.entryPrice} ${this.item.positionAmt}`);
      this.dlgEdit = true;
    },

    closeEditDlg() {
      this.dlgEdit = false;
      this.item = {};
    },

    updateEditItem() {
      this.closeEditDlg();
      console.log('updateEditItem:', this.item);
    },

    deleteEditItem() {
      this.closeEditDlg();
      console.log(this.item);
    },

    tryToCoverAll() {
      this.dlgCoverAll = true;
    },

    closeCoverAllDlg() {
      this.dlgCoverAll = false;
    },

    coverAll() {
      this.tasks.forEach((item) => {
        this.coverItem(item);
      })
      this.closeCoverAllDlg();
    },

    tryToCover(item) {
      this.item = item;
      this.dlgCover = true;
    },

    closeCoverDlg() {
      this.dlgCover = false;
      this.item = {};
    },

    cover() {
      this.coverItem(this.item)
      this.closeCoverDlg();
    },

    coverItem(item) {

      console.log('coverItem:', item)

      cancel_strategy_task({
        symbol: item.symbol, accountType: item.accountType, strategyName: item.strategyName,
        instanceId: item.instanceId, type: this.orderType
      }).then((res) => {
        console.log(res)
      }).catch((reason) => {
        console.error(reason)
      })

    },

  },

  mounted: function () {
    if (this.isAlive) {
      this.task_header.push({
        text: "Actions",
        value: "actions",
        sortable: false,
      });
    }
  },

  data() {
    return {
      item: {},
      isNew: false,
      dlgEdit: false,
      dlgCoverAll: false,
      dlgCover: false,
      task_header: [
        { text: "Symbol", value: "symbol" },
        { text: "Task Type", value: "type" },
        { text: "Side", value: "side" },
        { text: "Total Qty", value: "totalQty" },
        { text: "Traded Qty", value: "tradedQty" },
        { text: "Start Time", value: "startTime" },
        { text: "DeadLine", value: "deadline" },
        { text: "Rounds Left", value: "roundLeft" },
        { text: "Task Status", value: "status" },
        { text: "Params", value: "params" },
      ],
    };
  },
};
</script>

<style lang="scss">
</style>
