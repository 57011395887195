<template>
  <v-row>
    <v-col>
      <v-row class="text-h5"><v-col>Trades</v-col></v-row>
      <v-row>
        <v-col>
          <v-simple-table dense dark fixed-header height="420px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Price</th>
                  <th class="text-left">Amount</th>
                  <th class="text-left">Time</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in data"
                  :key="item.key"
                  :style="'color:' + item.color"
                >
                  <td :style="'width: ' + width[0]">{{ item.price }}</td>
                  <td :style="'width: ' + width[1]">{{ item.qty }}</td>
                  <td :style="'width: ' + width[2]">{{ item.time }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "TradeListPanel",

  components: {
  },

  props: {
    symbol: String,
    type: String,
    data: Array,
    width: Array,
  },

  watch: {},

  data() {
    return {};
  },
};
</script>

