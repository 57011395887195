<template>
    <v-simple-table dark width="100%">
      <template v-slot:default>
        <thead v-if="hasHeader">
          <tr>
            <th class="text-left">Price</th>
            <th class="text-left">Qty</th>
          </tr>
        </thead>
        <tbody :style="'color:' + color">
          <tr class='row-height' v-for="item in data" :key="item.price">
            <td :style="'width: ' + width[0]">{{ item.price }}</td>
            <td :style="'width: ' + width[1]">{{ item.qty }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
</template>

<script>
export default {
  name: "OrderbookItem",

  props: {
    symbol: String,
    data: Array,
    hasHeader: Boolean,
    color: String,
    width: Array,
  },

  watch: {},

  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.tim-note {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  width: 260px;
}

.row-height td {
  height: 20px !important;
  font-size: 14px !important;
}

</style>
