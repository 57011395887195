<template>
  <v-data-table
    dense
    width="100%"
    :headers="order_table_header"
    :items="orderTable"
    class="elevation-1"
    disable-pagination
    :items-per-page="NaN"
    hide-default-footer
  >
    <template v-slot:item.direction="{ item }">
      <div v-if="item.direction === 'SHORT'" style="color: red">
        {{ item.direction }}
      </div>
      <div style="color: green" v-else>{{ item.direction }}</div>
    </template>
    <template v-slot:item.leverage="{ item }">
      <v-combobox
        v-on:change="dispatchLeverageChangeEvent(item)"
        persistent-hint
        v-model="item.leverage"
        :items="leverage_items"
        dense
      ></v-combobox>
    </template>
    <template v-slot:item.percentage="{ item }">
      {{ `${item.percentage * 100}%` }}
    </template>
    <template v-slot:item.orderNum="{ item }">
      {{ item.direction === "SHORT" ? -item.orderNum : item.orderNum }}
    </template>
  </v-data-table>
</template>

<script>
import { tradeMixin, eventMixin } from "@/mixins";

import ConfirmDialog from "@/components/base/ConfirmDialog";

export default {
  name: "SelectedItemTable",

  components: {
    ConfirmDialog,
  },

  mixins: [tradeMixin, eventMixin],

  props: {
    orderTable: Array,
  },

  methods: {
  },

  mounted: function () {},

  data() {
    return {
      leverage_items: [1, 3, 5, 10, 20, 50],
      order_table_header: [
        { text: "Symbol", value: "symbol" },
        { text: "Leverage", value: "leverage" },
        { text: "Direction", value: "direction" },
        { text: "Percentage", value: "percentage" },
        { text: "Order Num(Calc)", value: "orderNum" },
        { text: "Step Size", value: "stepSize" },
        { text: "Order Qty(Formatted)", value: "orderQty" },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
