<template>
  <v-tabs>
    <v-tab key="positions">Positions
      <div v-if="positions !== undefined && positions.length > 0">({{ positions.length }})</div>
    </v-tab>
    <v-tab key="open_orders">Open Orders
      <div v-if="open_orders !== undefined && open_orders.length > 0">({{ open_orders.length }})</div>
    </v-tab>
    <v-tab key="historical_orders">Historical Orders
      <div v-if="historical_orders !== undefined && historical_orders.length > 0">({{ historical_orders.length }})</div>
    </v-tab>
    <v-tab v-if="activeTasks !== undefined && activeTasks.length > 0" key="activeTasks">Active Tasks
      <div>({{ activeTasks.length }})</div>
    </v-tab>
    <v-tab v-if="oldTasks !== undefined && oldTasks.length > 0" key="oldTasks">Finished Tasks
      <div>({{ oldTasks.length }})</div>
    </v-tab>

    <v-tab-item key="positions">
      <position-table :accountType="accountType" :is_strategy="true" :strategyName="strategyName"
        :instanceId="instanceId" :positions="positions" :gatewayName="gatewayName">
      </position-table>
    </v-tab-item>

    <v-tab-item key="open_orders">
      <order-table :isAlive="true" :gatewayName="gatewayName" :orders="open_orders" :accountType="accountType">
      </order-table>
    </v-tab-item>

    <v-tab-item key="historical_orders">
      <order-table :orders="historical_orders" :accountType="accountType">
      </order-table>
    </v-tab-item>

    <v-tab-item v-if="activeTasks !== undefined && activeTasks.length > 0" key="activeTasks">
      <task-table :isAlive="true" :tasks="activeTasks" :strategyName="strategyName" :instanceId="instanceId">
      </task-table>
    </v-tab-item>

    <v-tab-item v-if="oldTasks !== undefined && oldTasks.length > 0" key="oldTasks">
      <task-table :tasks="oldTasks" :strategyName="strategyName" :instanceId="instanceId">
      </task-table>
    </v-tab-item>
  </v-tabs>
</template>

<script>

import OrderTable from "@/views/dashboard/component/OrderTable";

import PositionTable from "@/views/dashboard/component/PositionTable";

import TaskTable from "@/views/dashboard/component/TaskTable";

export default {
  name: "StrategyInfoPanel",

  components: {
    OrderTable,
    PositionTable,
    TaskTable,
  },

  props: {
    accountType: {
      type: String,
      default: 'BINANCE_USDT_FUTURE'
    },
    strategyName: String,
    instanceId: String,
    gatewayName: String,
    open_orders: Array,
    historical_orders: Array,
    positions: Array,
    activeTasks: Array,
    oldTasks: Array,
  },
  methods: {
  },

  data() {
    return {
    };
  },
};
</script>
